import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, Breadcrumbs, Link, Typography } from '@mui/material';
import axios, { AxiosResponse } from 'axios';
import { red, green } from '@mui/material/colors';

// コールの型定義
interface Call {
    call_id: string;
    receiver_user_id: string;
    caller_user_id: string;
    call_at: string;
    is_assigned: boolean;
    is_completed: boolean;
    completed_at: string;
    created_at: string;
    updated_at: string;
}

const CallTable: React.FC = () => {
    const [calls, setCalls] = useState<Call[]>([]);

    useEffect(() => {
        // データの取得
        axios.get('https://utlab.ikeda042api.net/api/v2/call')
            .then((response: AxiosResponse<Call[]>) => {
                setCalls(response.data);
            })
            .catch((error: any) => {
                console.error("データ取得に失敗しました:", error);
            });
    }, []);

    const truncateCallId = (callId: string) => {
        return callId.length > 18 ? `${callId.substring(0, 18)}...` : callId;
    };

    const checkAlertStatus = (callAt: string) => {
        const [hour, minute, second] = callAt.split(':').map(Number);
        const callTime = new Date();
        callTime.setHours(hour, minute, second, 0);

        const oneHourAgo = new Date();
        oneHourAgo.setHours(oneHourAgo.getHours() - 1);

        return callTime < oneHourAgo;
    };

    return (
        <>
            <Box mb={5}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                        Top
                    </Link>
                    <Link underline="hover" color="inherit" href="/calls">
                        calls
                    </Link>
                </Breadcrumbs>
            </Box>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell><b>Call ID</b></TableCell>
                            <TableCell><b>受信者 ID</b></TableCell>
                            <TableCell><b>発信者 ID</b></TableCell>
                            <TableCell><b>発信時間</b></TableCell>
                            <TableCell><b>割当て済み</b></TableCell>
                            <TableCell><b>通話済み</b></TableCell>
                            <TableCell><b>生存確認アラート</b></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {calls.map((call) => {
                            const needsAlert = checkAlertStatus(call.call_at);
                            return (
                                <TableRow key={call.call_id}>
                                    <TableCell>{truncateCallId(call.call_id)}</TableCell>
                                    <TableCell>{truncateCallId(call.receiver_user_id)}</TableCell>
                                    <TableCell>{truncateCallId(call.caller_user_id)}</TableCell>
                                    <TableCell>{call.call_at}</TableCell>
                                    <TableCell>{call.is_assigned ? "Yes" : "No"}</TableCell>
                                    <TableCell>{call.is_completed ? "Yes" : "No"}</TableCell>
                                    <TableCell>
                                        <Typography
                                            style={{ color: needsAlert ? red[500] : green[500] }}
                                        >
                                            {needsAlert ? "要生存確認" : "生存確認済み"}
                                        </Typography>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default CallTable;
