// TopPage.tsx
import React from "react";
import { Box, Card, CardContent, Grid, Typography, Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import PeopleIcon from '@mui/icons-material/People';
import CallIcon from '@mui/icons-material/Call';

const TopPage: React.FC = () => {
    const navigate = useNavigate();

    const menuItems = [
        {
            title: "Users",
            icon: <PeopleIcon sx={{ fontSize: 50 }} />,
            path: '/users',
            description: "View and manage user information."
        },
        {
            title: "Calls",
            icon: <CallIcon sx={{ fontSize: 50 }} />,
            path: '/calls',
            description: "View and manage call data."
        }
    ];

    const handleNavigate = (path: string) => {
        navigate(path);
    };

    return (
        <Container>
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight="100vh">
                <Grid container spacing={4} justifyContent="center">
                    {menuItems.map((item, index) => (
                        <Grid item xs={12} sm={6} md={3} key={index}>
                            <Card
                                onClick={() => handleNavigate(item.path)}
                                sx={{
                                    cursor: 'pointer',
                                    textAlign: 'center',
                                    height: '200px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                    boxShadow: 6,
                                    transition: 'box-shadow 0.3s ease-in-out',
                                    '&:hover': {
                                        backgroundColor: 'lightgrey',
                                        boxShadow: 10
                                    }
                                }}
                            >
                                <CardContent>
                                    {item.icon}
                                    <Typography variant="h6" mt={2}>
                                        {item.title}
                                    </Typography>
                                    <Typography variant="body2" mt={1} color="textSecondary">
                                        {item.description}
                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Box>
        </Container>
    );
};

export default TopPage;
