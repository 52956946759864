// App.tsx
import './App.css';
import Nav from './components/Nav';
import { Box, Container } from '@mui/system';
import Grid from '@mui/material/Grid';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import UserTable from './components/UsersConsole';
import TopPage from './components/TopPage';
import CallTable from './components/CallConsole';
function App() {
  return (
    <Box sx={{ bgcolor: "000", color: 'black', minHeight: '120vh' }}>
      <Router>
        <Nav title={`行政用管理者コンソール`} />
        <Routes>
          <Route path="/" element={
            <Container>
              <Grid container spacing={4} justifyContent="center" marginTop={-20}>
                <Grid item xs={12}>
                  <TopPage />
                </Grid>
              </Grid>
            </Container>
          } />
          <Route path="/users" element={
            <Container>
              <Grid container spacing={4} justifyContent="center" marginTop={1}>
                <Grid item xs={12}>
                  <UserTable />
                </Grid>
              </Grid>
            </Container>
          } />
          <Route path="/calls" element={
            <Container>
              <Grid container spacing={4} justifyContent="center" marginTop={1}>
                <Grid item xs={12}>
                  <CallTable />
                </Grid>
              </Grid>
            </Container>
          } />
        </Routes>
      </Router>
    </Box>
  );
}

export default App;
