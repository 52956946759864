import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Box, Breadcrumbs, Link, Typography } from '@mui/material';
import axios, { AxiosResponse } from 'axios';

// ユーザーの型定義
interface User {
    user_id: string;
    type: string;
    name: string;
    description: string;
    phone_number: string;
    area_code: string;
    daily_wakeup_at: string;
}

const UserTable: React.FC = () => {
    const [users, setUsers] = useState<User[]>([]);

    useEffect(() => {
        // データの取得
        axios.get('https://utlab.ikeda042api.net/api/v2/user')
            .then((response: AxiosResponse<User[]>) => {
                setUsers(response.data);
            })
            .catch((error: any) => {
                console.error("データ取得に失敗しました:", error);
            });
    }, []);

    const truncateUserId = (userId: string) => {
        return userId.length > 12 ? `${userId.substring(0, 10)}...` : userId;
    };

    return (
        <>
            <Box mb={5}>
                <Breadcrumbs aria-label="breadcrumb">
                    <Link underline="hover" color="inherit" href="/">
                        Top
                    </Link>
                    <Link underline="hover" color="inherit" href="/users">
                        users
                    </Link>
                </Breadcrumbs>
            </Box>
            <TableContainer component={Paper} >
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell><b>User ID</b></TableCell>
                            <TableCell><b>Type</b></TableCell>
                            <TableCell><b>Name</b></TableCell>
                            <TableCell><b>Description</b></TableCell>
                            <TableCell><b>Phone Number</b></TableCell>
                            <TableCell><b>Area Code</b></TableCell>
                            <TableCell><b>Wakeup Time</b></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {users.map((user) => (
                            <TableRow key={user.user_id}>
                                <TableCell>{truncateUserId(user.user_id)}</TableCell>
                                <TableCell>{user.type}</TableCell>
                                <TableCell>{user.name}</TableCell>
                                <TableCell>{user.description}</TableCell>
                                <TableCell>{user.phone_number}</TableCell>
                                <TableCell>{user.area_code}</TableCell>
                                <TableCell>{user.daily_wakeup_at}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default UserTable;